import React from 'react';
import './ShimmerCard.css'; // Asegúrate de crear un archivo CSS para los estilos

const ShimmerCard = () => {
  return (
    <div className="shimmer-card">
      <div className="shimmer-logo" />
      <div className="shimmer-name" />
    </div>
  );
};

export default ShimmerCard;
