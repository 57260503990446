// src/HomeContext.js
import React, { createContext, useContext, useState } from 'react';

const HomeContext = createContext();

export const useHomeContext = () => {
  return useContext(HomeContext);
};

export const HomeProvider = ({ children }) => {
  const [homeData, setHomeData] = useState({
    headerData: null,
    iconsData: [],
    pharmacies: [],
    dutyPharmacy: null,
    events: [],
    loading: true,
  });

  return (
    <HomeContext.Provider value={{ homeData, setHomeData }}>
      {children}
    </HomeContext.Provider>
  );
};
