import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, IconButton, Stack, Grid2, Accordion, AccordionSummary, AccordionDetails, Button, Fab } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


function EventDetail() {
    const location = useLocation();
    const event = location.state;
    const { name, description, address, phone, imageList, mapsLocation } = event;


    return (
        <Box padding={1}>
            {/* Carousel de imágenes */}
            <Swiper
                spaceBetween={10}
                slidesPerView={1}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                style={{ paddingBottom: '2px' }}
            >
                {imageList.map((image, index) => (
                    <SwiperSlide key={index}>
                        <Box
                            component="img"
                            src={image.photo}
                            alt={`Image ${index + 1}`}
                            sx={{ width: '100%', height: 250, objectFit: 'cover' }}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

           {/* Card de información */}
<Card sx={{ mt: 2, mb: 2, borderRadius: 2, boxShadow: 3 }}>
    <CardContent>
        {/* Nombre */}
        {name && (
            <Typography variant="h5" fontWeight="bold">
                {name}
            </Typography>
        )}

        {/* Descripción */}
        {description && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
                {description}
            </Typography>
        )}

        {/* Dirección */}
        {event.location && (
            <>
                <Typography variant="subtitle1" fontWeight="bold">Dirección</Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body2">{event.location}</Typography>
                    <IconButton
                        color="primary"
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(mapsLocation)}`}
                        target="_blank"
                    >
                        <LocationOnIcon />
                    </IconButton>
                </Stack>
            </>
        )}

        {/* Teléfono */}
        {event.phone && (
            <>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2 }}>Teléfono</Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body2">{event.phone}</Typography>
                    <IconButton color="primary" onClick={() => navigator.clipboard.writeText(phone)}>
                        <ContentCopyIcon />
                    </IconButton>
                </Stack>
            </>
        )}

        {/* URL */}
        {event.url && (
            <>
                <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2 }}>Direccion web</Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body2">
                        <a href={event.url} target="_blank" rel="noopener noreferrer">
                            {event.url}
                        </a>
                    </Typography>
                </Stack>
            </>
        )}
    </CardContent>
</Card>

        </Box>
    );
}

export default EventDetail;