import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, CardMedia, Box, CircularProgress, Button, Fab, Modal, TextField, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref as dbRef, get, push, set, onValue } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL, getStorage } from 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';

const Comercio = ({ marketIndex }) => {
    const [marketData, setMarketData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [newPromotion, setNewPromotion] = useState({
        image: '',
        title: '',
        details: '',
        endDate: '',
        endDateTimestamp: '',
        isStockLimited: false,
        isVisible: true,
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [promotionToDelete, setPromotionToDelete] = useState(null);
    const [isStockLimited, setIsStockLimited] = useState(false); // Nuevo estado para el checkbox

    useEffect(() => {
        if (!user || user.isAnonymous) {
            navigate('/login');
        } else {
            const db = getDatabase();
            const marketsRef = dbRef(db, 'markets');

            get(marketsRef).then((snapshot) => {
                if (snapshot.exists()) {
                    const markets = snapshot.val();
                    const userMarketEntry = Object.entries(markets).find(([_, market]) => market.id === user.uid);

                    if (userMarketEntry) {
                        const [marketIndex, userMarket] = userMarketEntry;
                        setMarketData({ ...userMarket, index: marketIndex });
                    } else {
                        console.log("No se encontraron datos para este usuario.");
                    }
                }
                setLoading(false);
            }).catch((error) => {
                console.error("Error al obtener los mercados:", error);
                setLoading(false);
            });
        }
    }, [user, navigate]);

    const refreshPromotions = () => {
        if (!marketData || !marketData.index) return;

        const db = getDatabase();
        const marketRef = dbRef(db, `markets/${marketData.index}/promotions`);

        onValue(marketRef, (snapshot) => {
            if (snapshot.exists()) {
                setMarketData((prevData) => ({
                    ...prevData,
                    promotions: snapshot.val(),
                }));
            } else {
                setMarketData((prevData) => ({
                    ...prevData,
                    promotions: {},
                }));
            }
        });
    };

    const handleAddPromotion = async () => {
        if (!selectedImage) {
            alert("Por favor, selecciona una imagen.");
            return;
        }
        // Validar si se necesita la fecha de finalización
        if (!isStockLimited && !newPromotion.endDate) {
            alert("Por favor, selecciona una fecha de finalización.");
            return;
        }

        setLoading(true); // Iniciar indicador de carga

        try {
            const storage = getStorage();
            const storageRefPath = storageRef(storage, `promotions/${marketData.name}/${selectedImage.name}`);

            // Subir imagen a Firebase Storage
            await uploadBytes(storageRefPath, selectedImage);
            const downloadURL = await getDownloadURL(storageRefPath);


            // Datos de la promoción
            const promotionData = {
                ...newPromotion,
                image: downloadURL,

            };

            // Agregar timestamp solo si no se selecciona "hasta agotar stock"
            if (!isStockLimited && newPromotion.endDate) {
                // Convertir la fecha a timestamp y ajustar a UTC-3
                const endDate = new Date(newPromotion.endDate.split("/").reverse().join("-"));
                const endDateTimestamp = Math.floor((endDate.getTime() / 1000) + (3 * 60 * 60)); // Restar 3 horas en segundos
                promotionData.endDateTimestamp = endDateTimestamp; // Agregar el timestamp
            } else {
                promotionData.isStockLimited = true
            }

            // Guardar datos de la promoción en Firebase Database
            const db = getDatabase();
            const marketRef = dbRef(db, `markets/${marketData.index}/promotions`);

            await push(marketRef, promotionData);

            // Limpiar el formulario y cerrar modal
            setOpen(false);
            refreshPromotions();
            resetNewPromotion();
        } catch (error) {
            console.error("Error al agregar la promoción:", error);
        } finally {
            setLoading(false)
        }
    };

    const handleEditPromotion = (promotionId) => {
        // Lógica para editar la promoción
        // Por ejemplo, redirigir a otra página:
        navigate(`/edit-promotion/${promotionId}?marketIndex=${marketData.index}`);
    };

    const handleDeletePromotion = (promotionId) => {
        if (!marketData || !marketData.index) return;

        const db = getDatabase();
        const promotionRef = dbRef(db, `markets/${marketData.index}/promotions/${promotionId}`);

        set(promotionRef, null)
            .then(() => {
                console.log("Promoción eliminada");
                setConfirmDeleteOpen(false);
                refreshPromotions();
            })
            .catch((error) => {
                console.error("Error al eliminar la promoción:", error);
            });
    };

    const resetNewPromotion = () => {
        setNewPromotion({
            image: '',
            title: '',
            details: '',
            endDate: '',
            endDateTimestamp: '',
            isStockLimited: false,
            isVisible: true,
        });
        setSelectedImage(null);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        resetNewPromotion(); // Resetea al cerrar
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Si el campo es 'endDate', convertir la fecha al formato 'dd/MM/yyyy'
        if (name === "endDate") {
            const dateParts = value.split("-"); // Obtener partes de la fecha en 'yyyy-MM-dd'
            const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`; // Convertir a 'dd/MM/yyyy'
            setNewPromotion((prev) => ({ ...prev, [name]: formattedDate }));
        } else {
            setNewPromotion((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            setNewPromotion((prev) => ({ ...prev, image: file.name }));
        }
    };

    // Manejador para el checkbox
    const handleStockLimitedChange = (event) => {
        setIsStockLimited(event.target.checked);
        if (event.target.checked) {
            setNewPromotion((prev) => ({ ...prev, endDate: "" })); // Limpiar la fecha si se selecciona "hasta agotar stock"
        }
    };


    useEffect(() => {
        refreshPromotions(); // Cargar las promociones al montar el componente o cambiar marketIndex
    }, [marketIndex]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!marketData) {
        return <Typography variant="body1">No se encontraron datos para este comercio.</Typography>;
    }

    const today = new Date().toISOString().split("T")[0]; // Obtiene la fecha de hoy en formato "YYYY-MM-DD"

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
            <Card sx={{ display: 'flex', alignItems: 'center', width: '100%', height: '100px', marginBottom: '20px', marginTop: '8px' }}>
                <Box
                    component="img"
                    src={marketData.logo}
                    alt={marketData.name}
                    sx={{
                        width: '60px',
                        height: '60px',
                        paddingLeft: '8px',
                        borderRadius: '50%', // Esto hará que la imagen sea circular
                        objectFit: 'cover',  // Asegura que la imagen se recorte adecuadamente
                        marginRight: '16px', // Espacio entre la imagen y el texto
                    }}
                />
                <CardContent sx={{ flex: 1 }}>
                    <Typography variant="body1" component="div" fontWeight="bold">
                        {marketData.name}
                    </Typography>
                </CardContent>
                <IconButton aria-label="edit">
                    <EditIcon />
                </IconButton>
                <IconButton aria-label="logout">
                <LogoutIcon /> {/* Usar LogoutIcon en lugar de Logout */}
            </IconButton>
            </Card>

            <Typography variant="h5" gutterBottom sx={{ textAlign: 'left', paddingTop: '8px' }}>
                Promociones
            </Typography>

            {marketData.promotions && Object.keys(marketData.promotions).length > 0 ? (
                Object.keys(marketData.promotions).reverse().map((key) => {
                    const promotion = marketData.promotions[key];
                    return (
                        <Card key={key} sx={{ display: 'flex', flexDirection: 'column', marginBottom: '16px', elevation: 2, width: '100%' }}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={promotion.image}
                                alt={promotion.title}
                            />
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    {promotion.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {promotion.details}
                                </Typography>
                                {/* Condición para mostrar fecha de finalización o "Hasta agotar stock" */}
                                {promotion.isStockLimited ? (
                                    <Typography variant="body2" color="text.secondary">
                                        Hasta agotar stock
                                    </Typography>
                                ) : (
                                    <Typography variant="body2" color="text.secondary">
                                        Fecha de Finalización: {promotion.endDate}
                                    </Typography>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleEditPromotion(key)}
                                    sx={{ marginTop: '8px' }}
                                >
                                    Editar
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        setPromotionToDelete(key);
                                        setConfirmDeleteOpen(true);
                                    }}
                                    sx={{ marginTop: '8px', marginLeft: '8px' }}
                                >
                                    Eliminar
                                </Button>
                            </CardContent>
                        </Card>
                    );
                })
            ) : (
                <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', marginTop: '20px' }}>
                    No hay promociones, carga la primera con el botón +
                </Typography>
            )}
            <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={handleOpen}>
                <AddIcon />
            </Fab>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        width: 350,
                        bgcolor: 'background.paper',
                        p: 4,
                        borderRadius: '8px',
                        boxShadow: 24,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Agregar Promoción
                    </Typography>
                    <TextField
                        label="Título"
                        variant="outlined"
                        name="title"
                        value={newPromotion.title}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Detalles"
                        variant="outlined"
                        name="details"
                        value={newPromotion.details}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isStockLimited}
                                onChange={handleStockLimitedChange}
                                color="primary"
                            />
                        }
                        label="Hasta agotar stock"
                    />
                    <TextField
                        label="Fecha de Finalización"
                        type="date"
                        variant="outlined"
                        name="endDate"
                        value={newPromotion.endDate.split("/").reverse().join("-")} // Formato para el input
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required={!isStockLimited} // Solo requerido si no está seleccionado "hasta agotar stock"
                        slotProps={{
                            inputLabel: { shrink: true },
                            htmlInput: { min: today }
                        }}
                        disabled={isStockLimited} // Desactivar el campo de fecha si está seleccionado
                    />

                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" component="span" fullWidth>
                            Seleccionar imagen
                        </Button>
                    </label>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1, textAlign: 'center' }}>
                        {selectedImage ? selectedImage.name : 'No se ha seleccionado ninguna imagen.'}
                    </Typography>
                    {loading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '16px 0' }}>
                            <CircularProgress />
                        </Box>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddPromotion}
                        disabled={loading || !selectedImage || !newPromotion.title || !newPromotion.details || (!isStockLimited && !newPromotion.endDate)} // Desactivar el botón si es necesario
                        sx={{ marginTop: '16px' }}
                    >
                        Agregar Promoción
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
            >
                <Box sx={{ width: 300, padding: 4, margin: 'auto', marginTop: '100px', backgroundColor: 'white', borderRadius: '4px', textAlign: 'center' }}>
                    <Typography variant="h6">¿Estás seguro de que deseas eliminar esta promoción?</Typography>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeletePromotion(promotionToDelete)}
                        sx={{ marginTop: '16px' }}
                    >
                        Eliminar
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => setConfirmDeleteOpen(false)}
                        sx={{ marginTop: '16px', marginLeft: '8px' }}
                    >
                        Cancelar
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default Comercio;
