import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    IconButton,
    Stack,
    Grid2,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Fab
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './ImageCarousel.css';
import { Pagination } from 'swiper/modules';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function ShopDetails() {
    const location = useLocation();
    const shop = location.state;
    const { name, description, address, phone, imageList, services, mapsLocation, menuUrl, scheduleDays } = shop;
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(true); // Estado para controlar la carga de la imagen

    // Obtener el índice del día actual
    const todayIndex = new Date().getDay();
    const todaySchedule = (scheduleDays[todayIndex]?.day || "Cerrado").replace(/\|/g, ' ');

    // Construir la URL de WhatsApp
    let message = `Hola! Me contacto desde Jardinfo`;
    let shopPhone = phone.replace(/\D/g, ''); // Eliminar caracteres no numéricos
    const url = `https://wa.me/${shopPhone}?text=${encodeURIComponent(message)}`;

    return (
        <Box padding={1}>
            {/* Carousel de imágenes */}
            <Swiper
                spaceBetween={10}
                slidesPerView={1}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                style={{ paddingBottom: '2px' }}
            >
                {imageList
                    .filter(image => image.photo && image.photo.trim() !== '')
                    .map((image, index) => {
                       

                        return (
                            <SwiperSlide key={index}>
                                <div className="shimmer" style={{ display: loading ? 'block' : 'none' }}></div> {/* Placeholder shimmer */}
                                <Box
                                    component="img"
                                    src={image.photo}
                                    alt={`Image ${index + 1}`}
                                    sx={{ width: '100%', height: 250, objectFit: 'cover', display: loading ? 'none' : 'block' }}
                                    onLoad={() => setLoading(false)} // Oculta el shimmer cuando la imagen está cargada
                                />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>

            {/* Card de información */}
            <Card sx={{ mt: 2, mb: 2, borderRadius: 2, boxShadow: 3 }}>
                <CardContent>
                    <Typography variant="h5" fontWeight="bold">{name}</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2 }}>{description}</Typography>

                    <Typography variant="subtitle1" fontWeight="bold">Dirección</Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body2">{address}</Typography>
                        <IconButton
                            color="primary"
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(mapsLocation)}`}
                            target="_blank"
                        >
                            <LocationOnIcon />
                        </IconButton>
                    </Stack>

                    <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2 }}>Teléfono</Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body2">{phone}</Typography>
                        <IconButton color="primary" onClick={() => navigator.clipboard.writeText(phone)}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Stack>
                </CardContent>
            </Card>

            {/* Servicios */}
            <Grid2 container spacing={1} sx={{ mb: 2 }}>
                {services
                .filter(service => service.type && service.type.trim() !== '')
                .map((service, index) => (
                    <Grid2 item key={index}>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                color: 'black',
                                boxShadow: 2,
                                '&:hover': {
                                    boxShadow: 4,
                                },
                            }}
                        >
                            {service.type}
                        </Button>
                    </Grid2>
                ))}
            </Grid2>

            {/* Botón Ver el menú completo */}
            {menuUrl && (
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    href={menuUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ mb: 2 }}
                >
                    Ver el menú completo
                </Button>
            )}

            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ boxShadow: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Horario hoy: {todaySchedule}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {scheduleDays.map((schedule, index) => {
                        const dayName = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"][index];
                        const scheduleText = (schedule.day || "Cerrado").replace(/\|/g, ' ');
                        const scheduleLines = scheduleText.split('|');
                        return (
                            <Typography key={index} color={index === todayIndex ? "primary" : "textSecondary"}
                                sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
                                <span style={{ flex: 1 }}>{dayName}</span>
                                <span>
                                    {scheduleLines.map((line, lineIndex) => (
                                        <div key={lineIndex}>
                                            {line.trim()}
                                        </div>
                                    ))}
                                </span>
                            </Typography>
                        );
                    })}
                </AccordionDetails>
            </Accordion>

            {/* Botón flotante de WhatsApp */}
            <Fab
                color="success"
                variant="extended"
                onClick={() => window.open(url, '_blank')} // Abre la URL en una nueva pestaña solo al hacer clic
                sx={{
                    marginTop: 4,
                    position: 'fixed',
                    right: 16,
                    bottom: 16,
                    backgroundColor: '#25D366',
                    '&:hover': { backgroundColor: '#1DAF5B' }
                }}
            >
                <WhatsAppIcon sx={{ mr: 1 }} />
                Mensaje
            </Fab>
        </Box>
    );
}

export default ShopDetails;
