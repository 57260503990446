import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, update } from 'firebase/database';
import { TextField, Button, Box, CircularProgress, Typography, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams } from 'react-router-dom';

const EditPromotion = () => {
    const { promotionId } = useParams();
    const [promotionData, setPromotionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const marketIndex = searchParams.get('marketIndex');
    const [newPromotion, setNewPromotion] = useState({
        image: '',
        title: '',
        details: '',
        endDate: '',
        endDateTimestamp: '',
        isStockLimited: false,
        isVisible: true,
    });
    const [isStockLimited, setIsStockLimited] = useState(false); // Nuevo estado para el checkbox

    useEffect(() => {
        const db = getDatabase();
        const promotionRef = ref(db, `markets/${marketIndex}/promotions/${promotionId}`);

        get(promotionRef).then((snapshot) => {
            if (snapshot.exists()) {
                setPromotionData(snapshot.val());
            } else {
                console.log("No se encontró la promoción.");
            }
            setLoading(false);
        }).catch((error) => {
            console.error("Error al cargar la promoción:", error);
            setLoading(false);
        });
    }, [promotionId, marketIndex]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "endDate") {
            const dateParts = value.split("-"); // Obtener partes de la fecha en 'yyyy-MM-dd'
            const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`; // Convertir a 'dd/MM/yyyy'
            const endDate = new Date(value);
            const endDateTimestamp = Math.floor((endDate.getTime() / 1000) + (3 * 60 * 60)); // Restar 3 horas en segundos
            promotionData.endDateTimestamp = endDateTimestamp; // Agregar el timestamp
            setPromotionData((prev) => ({ ...prev, [name]: formattedDate }));
        } else {
            setPromotionData((prev) => ({ ...prev, [name]: value }));
        }

        // Agregar timestamp solo si no se selecciona "hasta agotar stock"
        if (!isStockLimited && promotionData.endDate) {
            // Convertir la fecha a timestamp y ajustar a UTC-3
            const endDate = new Date(promotionData.endDate);
            const endDateTimestamp = Math.floor((endDate.getTime() / 1000) + (3 * 60 * 60)); // Restar 3 horas en segundos
            promotionData.endDateTimestamp = endDateTimestamp; // Agregar el timestamp
        } else {
            promotionData.isStockLimited = true
        }
    };

    const handleStockLimitToggle = (e) => {
        setIsStockLimited(e.target.checked)
        setPromotionData({
            ...promotionData,
            isStockLimited: e.target.checked,
            endDate: e.target.checked ? '' : promotionData.endDate,
            endDateTimestamp: e.target.checked ? null : promotionData.endDateTimestamp
        });
    };

    const handleSave = () => {
        if (!promotionData) return;

        setUpdating(true);
        const db = getDatabase();
        const promotionRef = ref(db, `markets/${marketIndex}/promotions/${promotionId}`);

        update(promotionRef, promotionData)
            .then(() => {
                console.log("Promoción actualizada con éxito.");
                setUpdating(false);
                navigate(-1);
            })
            .catch((error) => {
                console.error("Error al actualizar la promoción:", error);
                setUpdating(false);
            });
    };


    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!promotionData) {
        return <Typography variant="body1">No se encontró la promoción.</Typography>;
    }

    const today = new Date().toISOString().split("T")[0]; // Obtiene la fecha de hoy en formato "YYYY-MM-DD"

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            padding: '16px',
            position: 'relative',
            overflowY: 'auto', // Añadir desplazamiento si el contenido excede la altura
        }}>
            {/* Botón de "Back" */}
            <IconButton
                onClick={() => navigate(-1)}
                sx={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
                    boxShadow: 3,
                }}
            >
                <ArrowBackIcon />
            </IconButton>

            <Typography 
            sx={{ marginTop: '64px' }} 
            variant="h5" gutterBottom>
                Editar Promoción
            </Typography>

            <TextField
                fullWidth
                margin="normal"
                label="Título"
                name="title"
                value={promotionData.title}
                onChange={handleChange}
            />
            <TextField
                fullWidth
                margin="normal"
                label="Detalles"
                name="details"
                value={promotionData.details}
                onChange={handleChange}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={promotionData.isStockLimited || false}
                        onChange={handleStockLimitToggle}
                        name="isStockLimited"
                        color="primary"
                    />
                }
                label="Hasta agotar stock"
            />
            {!promotionData.isStockLimited && (
                <TextField
                    label="Fecha de Finalización"
                    type="date"
                    variant="outlined"
                    name="endDate"
                    value={promotionData.endDate.split("/").reverse().join("-")} // Formato para el input
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    slotProps={{
                        inputLabel: { shrink: true },
                        htmlInput: { min: today }
                    }}
                />
            )}
            {/* Botón "Guardar Cambios" en la parte inferior */}
            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{
                    marginTop: '32px',
                    width: '100%',
                }}
                disabled={updating}
            >
                {updating ? <CircularProgress size={24} /> : 'Guardar Cambios'}
            </Button>
        </Box>
    );
};

export default EditPromotion;