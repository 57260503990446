
import './App.css';
import { Route, Routes } from 'react-router-dom'; // Importar solo Route y Routes
import Home from './pages/Home';
import Hospedajes from './pages/Hospedajes';
import Login from './pages/Login'
import Comercio from './pages/Comercio';
import PrivateRoute from './pages/PrivateRoute';
import EditPromotion from './pages/EditPromotion';  // Importa tu componente
import DetalleHospedaje from './pages/DetalleHospedaje';
import Restaurants from './pages/Restaurantes';
import Delivery from './pages/Delivery'
import Events from './pages/Events';
import Shops from './pages/Shops';
import EventDetail from './pages/EventDetail';
import ShopSearch from './pages/ShopSearch';

function App() {
  return (
    <Routes>
      {/* Ruta principal que carga la página de inicio */}
      <Route path="/" element={<Home />} />
      {/* Ruta para cargar la lista de hospedajes */}
      <Route path="/hospedajes" element={<Hospedajes />} />
      <Route path="/hospedaje/:name" element={<DetalleHospedaje />} />
      <Route path='/restaurant' element={<Restaurants/>}/> 
      <Route path='/delivery' element={<Delivery/>}/> 
      <Route path='/comercio/:name' element={<Shops/>}/> 
      <Route path='/buscador' element={<ShopSearch/>}/> 
      <Route path='/events' element={<Events/>}/> 
      <Route path='/evento/:name' element={<EventDetail/>}/> 
      <Route path="/login" element = {<Login/>}/>
      <Route path="/edit-promotion/:promotionId" element={<EditPromotion/>} />
      <Route path="/comercio"element={
            <PrivateRoute>
              <Comercio/>
            </PrivateRoute>
          }/>
    </Routes>
  );
}

export default App;
