import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase'; // Importa la instancia de Firebase Auth

const PrivateRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <div>Loading...</div>; // Puedes mostrar un spinner mientras Firebase verifica
  }

  return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
