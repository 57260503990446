import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { TextField, Button, Typography, Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase'; // Asegúrate de que este import sea correcto
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [user] = useAuthState(auth); 
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.isAnonymous) {
      navigate('/comercio', { replace: true });
    }
  }, [user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Por favor, completa todos los campos.');
      return;
    }
    
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
      console.error("Error:", error);
    }
  };

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: '16px',
        backgroundColor: '#3D01C3',
        position: 'relative', // Habilitar posicionamiento relativo para el botón
      }}
    >
      {/* Botón de "Back" */}
      <IconButton
        onClick={() => navigate('/')}
        sx={{
          position: 'absolute',
          top: 16,
          left: 16,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
          boxShadow: 3,
        }}
      >
        <ArrowBackIcon />
      </IconButton>

      <img src="/icon.png" alt="Logo de la aplicación" style={{ width: '200px', marginBottom: '16px' }} />
      <Typography variant="h4" sx={{ mb: 4, color: "white" }}>
        JARDINFO
      </Typography>

      {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}

      <form onSubmit={handleLogin}>
        <TextField
          fullWidth
          label="Correo Electrónico"
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ 
            mb: 2, 
            input: { color: 'white' }, 
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, 
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, 
            '& .MuiInputLabel-root': { color: 'white' },
          }}
        />
        <TextField
          fullWidth
          label="Contraseña"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ 
            mb: 3, 
            input: { color: 'white' }, 
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, 
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, 
            '& .MuiInputLabel-root': { color: 'white' },
          }}
        />
        <Button 
          fullWidth 
          variant="contained" 
          sx={{ backgroundColor: '#FF5733', '&:hover': { backgroundColor: '#C70039' }, color: 'white' }} 
          type="submit"
        >
          Iniciar Sesión
        </Button>
      </form>
    </Box>
  );
};

export default Login;
