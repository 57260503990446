import React, { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { database } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './Hospedajes.css';
import ShimmerCard from '../ShimmerCard';

function Hospedajes() {
  const [hospedajes, setHospedajes] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchHospedajes = async () => {
      try {
        const hospedajesRef = ref(database, 'hotels');
        const snapshot = await get(hospedajesRef);
        if (snapshot.exists()) {
          const hospedajesArray = Object.keys(snapshot.val()).map(key => ({
            id: key,
            ...snapshot.val()[key]
          }));
          setHospedajes(hospedajesArray);
        }
      } catch (error) {
        console.error("Error al recuperar los hospedajes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHospedajes();
  }, []);

  const handleCardClick = (hospedaje) => {
    navigate(`/hospedaje/${hospedaje.name}`, { state: hospedaje });
  };

  return (
    <div className="hospedajes-container">
      {loading ? (
        Array.from({ length: 8 }).map((_, index) => <ShimmerCard key={index} />)
      ) : (
        hospedajes.map((hospedaje) => (
          <div key={hospedaje.id} className="card" onClick={() => handleCardClick(hospedaje)}>
            <img src={hospedaje.logo} alt={hospedaje.name} className="card-logo" />
            <h3 className="card-name">{hospedaje.name}</h3>
          </div>
        ))
      )}
    </div>
  );
}

export default Hospedajes;
