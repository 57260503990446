import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import './UpcomingEvents.css'; // Asegúrate de tener el archivo CSS para los estilos.
import { useNavigate } from 'react-router-dom';

const UpcomingEvents = ({ events }) => {
  // Validar si hay eventos antes de renderizar
  if (!events || events.length === 0) {
    return null; // No renderizar si no hay eventos
  }

  return (
    <Box sx={{ overflowX: 'hidden', padding: '16px' }}>
      <Typography variant="h6" sx={{ textAlign: "left", marginRight: "10px", marginBottom: "8px", fontSize: "18px", fontWeight: "bold" }}>
        Próximos eventos
      </Typography>
      <Box className="scroll-container">
        {events.map((event, index) => (
          <EventCard key={event.id || index} event={event} />
        ))}
      </Box>
    </Box>
  );
};

const EventCard = ({ event }) => {

  const navigate = useNavigate(); // Obtiene la función de navegación

  const handleClick = () => {
    navigate(`/evento/${event.name}`, { state: event }); // Navega a la ruta deseada
  };

  return (
    <Box
    onClick= {handleClick}
      sx={{
        minWidth: { xs: '60%', sm: '30%' }, // Ajuste de tamaño para eventos
        marginX: "8px",
        marginBottom: "10px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Sombra
        borderRadius: "8px", // Bordes redondeados
        overflow: "hidden",
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)', // Efecto hover
        }
      }}
    >
      <img
        src={event.flyer}
        alt={event.title}
        style={{
          width: "100%",
          height: "150px", // Altura fija para las imágenes
          objectFit: "cover", // Mantener proporciones
        }}
      />
      <Box sx={{ padding: "8px" }}>
        <Typography variant="body1" sx={{ textAlign: "left", fontWeight: "bold" }}>
          {event.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default UpcomingEvents;
