import React, { useState } from 'react';
import { Fab, Modal, Box, Button, TextField, MenuItem, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import format from 'date-fns/format';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const DisponibilidadModal = ({ phone }) => {
    const [open, setOpen] = useState(false);
    const [checkInDate, setCheckInDate] = useState(null);
    const [checkOutDate, setCheckOutDate] = useState(null);
    const [adults, setAdults] = useState(0);
    const [children, setChildren] = useState(0);
    const [babies, setBabies] = useState(0);
    const [pets, setPets] = useState(0);
    const today = new Date();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const handleSubmit = () => {

        let message = `Hola! Me contacto desde Jardinfo, me gustaría conocer la disponibilidad del ${format(checkInDate, 'dd/MM/yy')} al ${format(checkOutDate, 'dd/MM/yy')}. Somos ${adults} adultos`;


        if (children > 0) message += `, ${children} niños`;
        if (babies > 0) message += `, ${babies} bebés`;
        if (pets > 0) message += `, y ${pets} mascotas`;

        // Obtener el número de teléfono del alojamiento
        const alojamientoNumero = phone.replace(/\D/g, ''); // Eliminar caracteres no numéricos

        // Verificar si el número es válido
        if (!alojamientoNumero) {
            alert("Número de teléfono no disponible");
            return;
        }

        // Construir la URL de WhatsApp
        const url = `https://wa.me/${alojamientoNumero}?text=${encodeURIComponent(message)}`;

        console.log("URL de WhatsApp:", url); // Debugging

        // Abrir WhatsApp
        window.open(url, '_blank');
    };

    return (
        <>
            <Fab
                variant="extended"
                color="primary"
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    width: 'auto',
                    paddingX: 2,
                    boxShadow: 3,
                }}
                onClick={handleOpen}
            >
                Disponibilidad
            </Fab>

            <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{
                    width: '80%', // Ajusta el ancho según lo que necesites
                    maxWidth: 600, // Ancho máximo para pantallas grandes
                    margin: 'auto',
                    padding: 1,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                }}>

                    <Typography 
                    variant="body1"
                    sx={{paddingBottom: 2 }}>
                        Consultar disponibilidad
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                        <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                            <DatePicker
                                label="Check-in"
                                value={checkInDate}
                                format='dd/MM/yyyy'
                                onChange={(newValue) => {
                                    setCheckInDate(newValue);
                                }}
                                minDate={today} // Solo permite seleccionar fechas desde hoy
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        value={checkInDate ? format(checkInDate, 'dd/MM/yyyy') : ''}
                                    />
                                )}
                            />
                            <DatePicker
                                label="Check-out"
                                value={checkOutDate}
                                format='dd/MM/yyyy'
                                onChange={(newValue) => {
                                    setCheckOutDate(newValue);
                                }}
                                minDate={checkInDate || today} // Solo permite seleccionar fechas después de check-in
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        value={checkOutDate ? format(checkOutDate, 'dd/MM/yyyy') : ''}
                                    />
                                )}
                            />
                        </div>
                    </LocalizationProvider>

                    <TextField
                        select
                        label="Adultos"
                        value={adults}
                        onChange={(e) => setAdults(e.target.value)}
                        sx={{ marginTop: 2, width: '100%' }}
                    >
                        {[...Array(10).keys()].map((num) => (
                            <MenuItem key={num} value={num}>
                                {num}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        label="Niños"
                        value={children}
                        onChange={(e) => setChildren(e.target.value)}
                        sx={{ marginTop: 2, width: '100%' }}
                    >
                        {[...Array(10).keys()].map((num) => (
                            <MenuItem key={num} value={num}>
                                {num}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        label="Bebés"
                        value={babies}
                        onChange={(e) => setBabies(e.target.value)}
                        sx={{ marginTop: 2, width: '100%' }}
                    >
                        {[...Array(10).keys()].map((num) => (
                            <MenuItem key={num} value={num}>
                                {num}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        label="Mascotas"
                        value={pets}
                        onChange={(e) => setPets(e.target.value)}
                        sx={{ marginTop: 2, width: '100%' }}
                    >
                        {[...Array(5).keys()].map((num) => (
                            <MenuItem key={num} value={num}>
                                {num}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ marginTop: 4, backgroundColor: '#25D366', '&:hover': { backgroundColor: '#1DAF5B' } }}
                        startIcon={<WhatsAppIcon />}
                    >
                        Enviar Consulta
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default DisponibilidadModal;
