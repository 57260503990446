import React, { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { database } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './Hospedajes.css';
import ShimmerCard from '../ShimmerCard';

function Restaurants() {
  const [hospedajes, setRestaurant] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchRestaurants = async () => {
      try {
        const hospedajesRef = ref(database, 'restaurants');
        const snapshot = await get(hospedajesRef);
        if (snapshot.exists()) {
          const hospedajesArray = Object.keys(snapshot.val()).map(key => ({
            id: key,
            ...snapshot.val()[key]
          }));
          setRestaurant(hospedajesArray);
        }
      } catch (error) {
        console.error("Error al recuperar los hospedajes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurants();
  }, []);

  const handleCardClick = (restaurant) => {
    navigate(`/comercio/${restaurant.name}`, { state: restaurant });
  };

  return (
    <div className="hospedajes-container">
      {loading ? (
        Array.from({ length: 8 }).map((_, index) => <ShimmerCard key={index} />)
      ) : (
        hospedajes.map((restaurant) => (
          <div key={restaurant.id} className="card" onClick={() => handleCardClick(restaurant)}>
            <img src={restaurant.logo} alt={restaurant.name} className="card-logo" />
            <h3 className="card-name">{restaurant.name}</h3>
          </div>
        ))
      )}
    </div>
  );
}

export default Restaurants;
